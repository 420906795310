// Malay
export default{
    form: {
        startSurveyButton: "Mari mula",
        nextButton: "Seterusnya",
        submitAnswerButton: "Hantar Jawapan",
        continueButton: "Teruskan",
        finishButton: "Selesai",
        headerPercentageCompletion: 'siap',
        headerQuestionsCompleted: 'soalan',
        headerDefaultErrorMessage: 'Ups! Ada yang tidak kena.',
        brandingPoweredBy: 'Dikuasakan oleh',
        brandingPromoWhySurvey: 'Mengapa buat kaji selidik jika anda boleh guna <b>{product}</b>?',
        brandingPromoGetStarted: 'Mula hari ini!',
        submissionPending: "Voiceform anda sedang dihantar. Jangan tutup tetingkap pelayar anda.",
        submissionCanCloseWindow: "Voiceform anda telah berjaya dihantar. Anda boleh tutup tetingkap sekarang 👍",
        mediaAttachmentOverlayCloseButton: "Tutup",
        expiredFormHeader: "Terima kasih kerana singgah!",
        expiredFormMessage: "Nampaknya kaji selidik ini telah tamat tempoh. Sila hubungi pencipta voiceform untuk memaklumkan mereka.",
        notPublishedFormHeader: "Voiceform belum diterbitkan!",
        notPublishedFormMessage: "Nampaknya kaji selidik ini belum diterbitkan. Sila hubungi pencipta voiceform untuk memaklumkan mereka.",
        notPublishedFormRetryButton: "Cuba Lagi",
        errorFormHeader: "Ups!",
        errorFormMessage: "Mungkin ada yang tidak kena. Sila cuba lagi kemudian.",
        errorFormRetryButton: "Cuba Lagi",
        emptyFormHeader: "Terima kasih kerana singgah!",
        emptyFormMessage: "Ups! Nampaknya borang ini kosong, tiada soalan atau medan untuk diisi. Kami mohon maaf atas kesulitan ini. Sila hubungi pencipta borang untuk memaklumkan masalah ini. Terima kasih atas pemahaman dan kerjasama anda!",

        restoreFormHeader: "Nampaknya anda ada borang yang belum selesai!",
        restoreFormMessage: "Adakah anda ingin teruskan dari tempat anda berhenti?",
        restoreFormButton: "Teruskan Borang",
        restoreFormButtonNew: "Mulakan Semula",

        audioPermissionHeader: "Adakah anda mahu menjawab dengan suara?",
        audioPermissionMessage: "Bercakap akan memudahkan dan mempercepatkan jawapan.",
        audioPermissionAcceptButton: "Ya, saya mahu",
        audioPermissionDenyButton: "Tidak, terima kasih",
        audioPermissionErrorHeader: "Mikrofon tidak tersedia",
        audioPermissionErrorMessage: "Sila semak kebenaran pelayar untuk mengaktifkan akses mikrofon.",
        audioPermissionErrorButton: "OK",
        audioRecorderFailedUpload: "Gagal memuat naik rakaman",
        silenceDetectorError: "Kami tidak dapat mendengar anda. Sila semak tetapan mikrofon anda.",
        audioRecorderButtonRecord: "Mula Rakaman",
        audioRecorderButtonRecordMore: "Rakaman Lagi",
        voiceResponseSelectionMessage: "Pilih cara anda ingin menjawab...",
        voiceResponseSelectionOrDivider: "atau",
        voiceResponseTextInputPlaceholder: "Taip jawapan",
        voiceResponseTextInputCharacterCounterMinMessage: "Sila masukkan sekurang-kurangnya {min} aksara",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} aksara (minimum {min} aksara)",
        voiceResponseTextInputCharacterValidationMessage: "{count} aksara (minimum {min} aksara)",

        // checkbox
        checkboxValidationTooFew: "Anda mesti pilih sekurang-kurangnya {min} pilihan",
        checkboxValidationTooMany: "Anda hanya boleh pilih maksimum {max} pilihan",
        checkboxNoneOfTheAboveOption: "Tiada satu pun di atas",
        checkboxInvalidAnswer: "Jawapan tidak sah.",

        // datepicker
        datePickerPlaceholder: "Pilih tarikh",

        // dropdown
        // email
        emailLabel: "E-mel",
        emailInvalid: "E-mel tidak sah.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Rakam video",
        fileUploadPluginScreenCast: "Rakaman skrin",
        fileUploadVideoImportFilesDefault: 'Rakam atau muat naik video melalui:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Pilih pilihan rakaman video:',
        fileUploadVideoImportFilesNoCamera: 'Tekan butang untuk rakam video skrin',
        fileUploadVideoImportFilesNoScreenCast: 'Tekan butang untuk rakam video',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Tiada pilihan untuk rakaman video',

        // matrix
        matrixValidationMessage: "Jawapan sah.",
        matrixRow: "Baris",
        matrixColumn: "Lajur",
        matrixRowRequired: "Baris {rowTitle} diperlukan.",
        matrixRadioGroup: "Kumpulan radio",
        matrixCheckboxGroup: "Kumpulan kotak pilihan",
        matrixGroupRequired: "Untuk baris {rowTitle}. {type} {groupTitle} diperlukan.",
        matrixColumnRequired: "Untuk baris {rowTitle}. Lajur {columnTitle} diperlukan.",
        matrixColumnInvalid: "Untuk baris {rowTitle}. Lajur {columnTitle} tidak sah.",
        matrixRequired: "Diperlukan.",
        matrixNumericMustBeNumber: "Mesti nombor.",
        matrixNumericMustBeGreaterThenMin: "Mesti lebih besar daripada {min}.",
        matrixNumericMustBeLessThenMax: "Mesti kurang daripada {max}.",
        matrixNumericMustBeInteger: "Mesti nombor bulat.",
        matrixNumericInvalidNumber: "Nombor tidak sah.",

        // name
        nameLabel: "Nama",
        nameInvalid: "Nyatakan nama dan nama keluarga.",

        // nps
        npsNotLikely: "Tidak mungkin sama sekali",
        npsExtremelyLikely: "Sangat mungkin",

        // numeric input
        numericInputRequired: "Diperlukan.",
        numericInputMustBeNumber: "Mesti nombor.",
        numericInputMustBeGreaterThenMin: "Mesti lebih besar daripada {min}.",
        numericInputMustBeLessThenMax: "Mesti kurang daripada {max}.",
        numericInputMustBeInteger: "Mesti nombor bulat.",
        numericInputInvalidNumber: "Nombor tidak sah.",
        numericInputPlaceholder: "Masukkan nombor",

        // phone
        phoneInvalid: "Nombor telefon tidak sah.",
        phoneCountrySelectorLabel: 'Kod negara',
        phoneCountrySelectorError: 'Pilih negara',
        phoneNumberLabel: 'Nombor telefon',
        phoneExample: 'Contoh:',

        // boolean
        booleanYesLabel: "Ya",
        booleanNoLabel: "Tidak",

        //questionStep
        questionStepAudioQuestionLabel: "Soalan audio",

        // errors
        invalidPhoneNumber: "{phone} adalah nombor telefon yang tidak sah.",
        invalidEmail: "{email} adalah alamat e-mel yang tidak sah.",
        questionIsRequired: "Soalan diperlukan.",
        answerIsNotValid: "Jawapan tidak sah.",
        unfinishedProbingDialogError: "Sila selesaikan dialog.",
        cannotResumePartialResponse: "Tidak boleh meneruskan respons separa.",
        failedToSubmitForm: "Gagal menghantar borang. Sila semak sambungan internet anda dan cuba lagi."
    }
}
